.whale-tracker-container {
    padding: 20px;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .whale-tracker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    background: #2a2a2a;
    padding: 20px;
    border-radius: 12px;
  }
  
  .whale-tracker-header h2 {
    margin: 0;
    color: #fff;
  }
  
  .controls {
    display: flex;
    gap: 15px;
  }
  
  .chain-selector,
  .timeframe-selector {
    padding: 8px 16px;
    border-radius: 8px;
    background: #1a1d23;
    color: white;
    border: 1px solid #3a3f4b;
    cursor: pointer;
    font-size: 14px;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .stat-card {
    background: #2a2a2a;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
  }
  
  .stat-card h3 {
    color: #8a8f98;
    margin: 0 0 10px 0;
    font-size: 0.9rem;
  }
  
  .stat-card p {
    color: #fff;
    font-size: 1.4rem;
    margin: 0;
    font-weight: 600;
  }
  
  .chart-container {
    background: #2a2a2a;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 30px;
    height: 400px;
  }
  
  .transactions-table {
    background: #2a2a2a;
    padding: 20px;
    border-radius: 12px;
  }
  
  .transactions-table h3 {
    margin: 0 0 20px 0;
    color: #fff;
  }
  
  .table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 -20px;
    padding: 0 20px;
    width: calc(100% + 40px);
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #3a3f4b;
  }
  
  th {
    color: #8a8f98;
    font-weight: 500;
  }
  
  td {
    color: #fff;
  }
  
  .value-cell {
    color: #4CAF50;
    font-weight: 500;
  }
  
  .address-cell {
    font-family: monospace;
  }
  
  .hash-cell a {
    color: #00bcd4;
    text-decoration: none;
  }
  
  .hash-cell a:hover {
    text-decoration: underline;
  }
  
  .loading-spinner {
    text-align: center;
    padding: 40px;
    color: #8a8f98;
  }
  
  .error-message {
    background: #ff44445e;
    color: #ff4444;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin: 20px 0;
  }
  
  @media (max-width: 768px) {
    .whale-tracker-header {
      flex-direction: column;
      gap: 15px;
    }
  
    .controls {
      width: 100%;
      flex-direction: column;
    }
  
    .chain-selector,
    .timeframe-selector {
      width: 100%;
    }
  
    .stats-grid {
      grid-template-columns: 1fr;
    }
  }
  
  /* Improved title styles */
  .section-title {
    position: relative;
    font-size: 1.5rem;
    font-family: 'Inter', sans-serif; /* Make sure this matches your app's font */
    color: #fff;
    padding-bottom: 8px;
    margin-bottom: 20px;
  }
  
  .section-title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #4CAF50, transparent);
    transition: width 0.3s ease;
  }
  
  .section-title:hover::after {
    width: 50%;
  }
  
  /* Stats cards improvements */
  .stat-card {
    background: #2a2a2a;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    position: relative;
    transition: transform 0.2s ease;
  }
  
  .stat-card:hover {
    transform: translateY(-2px);
  }
  
  .stat-card .tooltip {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 0.8rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    width: max-content;
    max-width: 250px;
    z-index: 1000;
  }
  
  .stat-card:hover .tooltip {
    opacity: 1;
    visibility: visible;
    top: -50px;
  }
  
  /* Chart improvements */
  .chart-container {
    margin-top: 40px; /* Add more space above chart */
  }
  
  /* Fix overlapping x-axis labels */
  .chart-container canvas {
    margin-bottom: 30px; /* Add more space below chart */
  }
  
  /* Dropdown styling */
  .chain-selector,
  .timeframe-selector {
    font-family: 'Inter', sans-serif;
    padding: 8px 16px;
    border-radius: 8px;
    background: #1a1d23;
    color: white;
    border: 1px solid #3a3f4b;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
  }
  
  .chain-selector:hover,
  .timeframe-selector:hover {
    background: #2a2f3a;
    border-color: #4CAF50;
  }
  
  /* Chart title and font consistency */
  .chart-title {
    font-family: 'Inter', sans-serif;
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 16px;
  }
  
  .chart-subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #8a8f98;
    margin-bottom: 24px;
  }
  
  /* Add glass morphism effect to cards */
  .stat-card, .whale-tracker-header, .chart-container, .transactions-table {
    background: rgba(42, 42, 42, 0.8);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.36);
  }
  
  /* Smooth gradient animation for section titles */
  .section-title::after {
      background: linear-gradient(90deg, 
        #4CAF50, 
        #66bb6a,
        #81c784, 
        transparent
      );
      background-size: 200% 100%;
      animation: gradientMove 3s ease infinite;
    }
  
  @keyframes gradientMove {
    0% { background-position: 100% 0; }
    100% { background-position: -100% 0; }
  }
  
  /* Modern table styling */
  .table-container {
    border-radius: 12px;
    overflow: hidden;
  }
  
  table {
    border-spacing: 0;
  }
  
  tr:hover {
    background: rgba(255, 255, 255, 0.05);
    transition: background 0.2s ease;
  }
  
  /* Improved dropdown styling */
  .chain-selector,
  .timeframe-selector {
    background: rgba(26, 29, 35, 0.8);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .chain-selector:focus,
  .timeframe-selector:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }
  
  /* Skeleton loading animation */
  @keyframes shimmer {
    0% { background-position: -1000px 0; }
    100% { background-position: 1000px 0; }
  }
  
  .loading-spinner {
    background: linear-gradient(
      90deg,
      rgba(42, 42, 42, 0) 0%,
      rgba(42, 42, 42, 0.5) 50%,
      rgba(42, 42, 42, 0) 100%
    );
    background-size: 1000px 100%;
    animation: shimmer 2s infinite;
  }
  
  /* Improved tooltip animation */
  .stat-card .tooltip {
    transform: translateX(-50%) translateY(10px);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .stat-card:hover .tooltip {
    transform: translateX(-50%) translateY(0);
  }
  
  /* Subtle card hover effects */
  .stat-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.4);
  }
  
  /* Value cell animation */
  .value-cell {
    position: relative;
    overflow: hidden;
  }
  
  .value-cell::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #4CAF50;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }
  
  tr:hover .value-cell::after {
    transform: scaleX(1);
  }
  
  /* Responsive improvements */
  @media (max-width: 768px) {
    .stat-card {
      transform: none !important;
      box-shadow: none;
    }
    
    .tooltip {
      display: none;
    }
  }
  
  /* Mobile-specific table styles */
  @media (max-width: 768px) {
    .transactions-table {
      padding: 10px;
      margin: 0 -10px;
    }
  
    .table-container {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  
    /* Set minimum widths for table columns */
    table {
      min-width: 800px; /* Ensure table has minimum width to show all content */
    }
  
    th, td {
      padding: 8px;
      white-space: nowrap; /* Prevent text wrapping */
      min-width: 80px; /* Minimum width for each column */
    }
  
    /* Specific column widths */
    th:nth-child(1), td:nth-child(1) { /* Time column */
      min-width: 140px;
    }
  
    th:nth-child(2), td:nth-child(2) { /* Value column */
      min-width: 120px;
    }
  
    th:nth-child(3), td:nth-child(3), /* From column */
    th:nth-child(4), td:nth-child(4) { /* To column */
      min-width: 130px;
    }
  
    th:nth-child(5), td:nth-child(5) { /* Hash column */
      min-width: 140px;
    }
  
    /* Add visual indicator for scrollable content */
    .transactions-table::after {
      content: '← Scroll →';
      display: block;
      text-align: center;
      padding: 10px;
      color: #8a8f98;
      font-size: 0.8rem;
      background: rgba(42, 42, 42, 0.8);
      position: sticky;
      left: 0;
      right: 0;
      bottom: 0;
    }
  } 