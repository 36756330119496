/* src/pages/Compare.css */

.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Title styling */
.container h2 {
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 15px;
}

.container h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #00bcd4, #4CAF50);
  transition: width 0.3s ease;
}

.container h2:hover::after {
  width: 120px;
}

.compare-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #1a1d23;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Crypto selection section */
.crypto-selection {
  background: #2a2d35;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.crypto-selection h3 {
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #454545;
}

.crypto-options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  padding: 10px;
}

/* Checkbox styling */
.checkbox-label {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
  background: #1a1d23;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #454545;
}

.checkbox-label:hover {
  transform: translateY(-2px);
  background: #2a2d35;
  border-color: #00bcd4;
}

.checkbox-label input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #454545;
  border-radius: 4px;
  background: #1a1d23;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.checkbox-label input[type="checkbox"]:checked {
  background: #00bcd4;
  border-color: #00bcd4;
}

.checkbox-label input[type="checkbox"]:checked::after {
  content: '✓';
  position: absolute;
  color: #fff;
  font-size: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox-label img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

/* Comparison table styling */
.comparison-table {
  overflow-x: auto;
  max-width: 100%;
  background: #2a2d35;
  padding: 0;
  position: relative;
}

/* Basic cell styling */
.comparison-table th,
.comparison-table td {
  background: #2a2d35;
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  min-width: 150px;
  box-sizing: border-box;
}

/* First column styling - explicitly remove sticky */
.comparison-table th:first-child,
.comparison-table td:first-child {
  position: static !important; /* Force override any other positioning */
  text-align: left;
  color: #8a8f98;
  background: #1a1d23;
  z-index: auto;
  box-shadow: none;
}

/* Table structure */
.comparison-table table {
  min-width: 800px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

/* Header styling */
.comparison-table thead th {
  background: #1a1d23 !important;
  font-weight: 600;
}

/* Hover effects */
.comparison-table tr:hover td {
  background: #323642;
}

.comparison-table tr:hover td:first-child {
  background: #1e2128;
}

/* Loading state */
.loading {
  text-align: center;
  padding: 20px;
  color: #8a8f98;
  font-size: 1.1rem;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }
  
  .crypto-options {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  
  .comparison-table {
    margin: 0 -10px;
    border-radius: 0;
  }
  
  .checkbox-label {
    font-size: 0.9rem;
  }
}
  
/* Add glass morphism effect to containers */
.compare-content {
  background: rgba(26, 29, 35, 0.8);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.crypto-selection, .comparison-table {
  background: rgba(42, 45, 53, 0.8);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Add animated gradient underline for headings */
@keyframes gradientFlow {
  0% { background-position: 100% 0; }
  100% { background-position: -100% 0; }
}

.container h2::after,
.crypto-selection h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, 
    #00bcd4, 
    #4CAF50,
    #00bcd4
  );
  background-size: 200% 100%;
  animation: gradientFlow 6s linear infinite;
}

/* Enhanced checkbox styling */
.checkbox-label {
  position: relative;
  overflow: hidden;
  border: none;
}

.checkbox-label::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: linear-gradient(90deg, 
    #00bcd4, 
    #4CAF50,
    #00bcd4
  );
  background-size: 200% 100%;
  animation: gradientFlow 6s linear infinite;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.checkbox-label:hover::before {
  opacity: 1;
}

/* Animated table rows */
.comparison-table tr {
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.comparison-table tr:hover {
  transform: scale(1.01);
  background: rgba(50, 54, 66, 0.9);
}

/* Add subtle animation for value changes */
@keyframes valueChange {
  0% { transform: translateY(-5px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.comparison-table td {
  position: relative;
}

.comparison-table td.value-changed {
  animation: valueChange 0.3s ease-out;
}

/* Enhanced table header styling */
.comparison-table th {
  background: rgba(26, 29, 35, 0.9);
  backdrop-filter: blur(5px);
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

/* Scrollbar styling */
.comparison-table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.comparison-table::-webkit-scrollbar-track {
  background: rgba(26, 29, 35, 0.5);
  border-radius: 4px;
}

.comparison-table::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #00bcd4, #4CAF50);
  border-radius: 4px;
}

/* Loading animation */
@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.loading {
  animation: pulse 1.5s ease-in-out infinite;
  background: linear-gradient(90deg, #00bcd4, #4CAF50);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 500;
}

/* Responsive improvements */
@media (max-width: 768px) {
  .compare-content {
    backdrop-filter: none;
  }
  
  .comparison-table {
    backdrop-filter: none;
  }
  
  .checkbox-label::before {
    animation: none;
  }
}
  
/* Modern glassmorphism and neumorphism effects */
.compare-content {
  background: rgba(26, 29, 35, 0.7);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.3),
    inset 0 0 32px rgba(255, 255, 255, 0.05);
}

/* Enhanced crypto selection cards */
.checkbox-label {
  background: rgba(26, 29, 35, 0.8);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.checkbox-label:hover {
  transform: translateY(-4px) scale(1.02);
  background: rgba(42, 45, 53, 0.9);
  box-shadow: 
    0 8px 20px rgba(0, 0, 0, 0.2),
    inset 0 0 20px rgba(255, 255, 255, 0.05);
}

/* Modern table styling */
.comparison-table {
  background: rgba(42, 45, 53, 0.7);
  backdrop-filter: blur(15px);
  border-radius: 16px;
  overflow: hidden;
}

.comparison-table table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.comparison-table tr {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.comparison-table td {
  background: rgba(26, 29, 35, 0.6);
  backdrop-filter: blur(5px);
  border: none;
  position: relative;
}

/* Animated gradient borders */
@keyframes borderFlow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.comparison-table tr:hover td {
  background: rgba(42, 45, 53, 0.8);
}

.comparison-table tr:hover td::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 1px;
  border-radius: inherit;
  background: linear-gradient(
    45deg,
    #00bcd4,
    #4CAF50,
    #2196F3,
    #00bcd4
  );
  background-size: 200% 200%;
  animation: borderFlow 3s linear infinite;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* Modern value change animation */
@keyframes valueUpdate {
  0% { transform: scale(1.1); filter: hue-rotate(0deg); }
  50% { transform: scale(1.2); filter: hue-rotate(180deg); }
  100% { transform: scale(1); filter: hue-rotate(360deg); }
}

.value-changed {
  animation: valueUpdate 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Enhanced crypto icons */
.checkbox-label img,
.comparison-table th img {
  filter: drop-shadow(0 0 8px rgba(0, 188, 212, 0.3));
  transition: all 0.3s ease;
}

.checkbox-label:hover img,
.comparison-table th:hover img {
  transform: scale(1.15) rotate(5deg);
  filter: drop-shadow(0 0 12px rgba(0, 188, 212, 0.5));
}

/* Modern scrollbar */
.comparison-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.comparison-table::-webkit-scrollbar-track {
  background: rgba(26, 29, 35, 0.3);
  border-radius: 3px;
}

.comparison-table::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #00bcd4, #4CAF50);
  border-radius: 3px;
  border: 2px solid rgba(26, 29, 35, 0.5);
}

/* Loading animation */
@keyframes shimmer {
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
}

.loading {
  background: linear-gradient(
    90deg,
    rgba(42, 45, 53, 0) 25%,
    rgba(0, 188, 212, 0.2) 50%,
    rgba(42, 45, 53, 0) 75%
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
  border-radius: 8px;
  padding: 20px;
  backdrop-filter: blur(5px);
}

/* Responsive improvements */
@media (max-width: 768px) {
  .crypto-options {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }

  .checkbox-label {
    padding: 12px;
    font-size: 0.85rem;
  }

  .comparison-table td,
  .comparison-table th {
    padding: 12px 8px;
    font-size: 0.9rem;
  }
}
  
/* Use hardware acceleration and reduce paint operations */
.compare-content {
  background: rgba(26, 29, 35, 0.7);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  will-change: transform; /* Hint for browser optimization */
  transform: translateZ(0); /* Force GPU acceleration */
}

/* Optimize checkbox label animations */
.checkbox-label {
  background: rgba(26, 29, 35, 0.8);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
              box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, box-shadow;
  transform: translateZ(0);
}

.checkbox-label:hover {
  transform: translateY(-4px) translateZ(0);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Optimize table animations */
.comparison-table tr {
  transform: translateZ(0);
  will-change: transform, opacity;
}

.comparison-table td {
  background: rgba(26, 29, 35, 0.6);
  transition: background-color 0.2s ease;
  will-change: background-color;
}

/* Simplified gradient border animation */
.comparison-table tr:hover td::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 1px;
  border-radius: inherit;
  background: linear-gradient(
    45deg,
    #00bcd4,
    #4CAF50
  );
  opacity: 0.5;
  transition: opacity 0.2s ease;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* Optimized value change animation */
@keyframes valueUpdate {
  0% { transform: scale(1.05) translateZ(0); }
  50% { transform: scale(1.1) translateZ(0); }
  100% { transform: scale(1) translateZ(0); }
}

.value-changed {
  animation: valueUpdate 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
}

/* Optimized crypto icon animations */
.checkbox-label img,
.comparison-table th img {
  transform: translateZ(0);
  will-change: transform;
  transition: transform 0.2s ease;
}

.checkbox-label:hover img,
.comparison-table th:hover img {
  transform: scale(1.15) translateZ(0);
}

/* Optimized loading animation */
@keyframes shimmer {
  0% { transform: translateX(-100%) translateZ(0); }
  100% { transform: translateX(100%) translateZ(0); }
}

.loading {
  position: relative;
  overflow: hidden;
  background: rgba(42, 45, 53, 0.7);
}

.loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 188, 212, 0.2),
    transparent
  );
  transform: translateX(-100%) translateZ(0);
  animation: shimmer 1.5s infinite linear;
  will-change: transform;
}
  
/* Add horizontal scrolling to comparison table */
.comparison-table {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  position: relative;
  padding-bottom: 12px; /* Space for scrollbar */
  background: #2a2d35; /* Match table background */
}

/* Ensure table maintains minimum width for columns */
.comparison-table table {
  min-width: 800px; /* Minimum width to prevent squishing */
  width: 100%;
}

/* Keep the first column (Metric names) fixed while scrolling */
.comparison-table th:first-child,
.comparison-table td:first-child {
  position: sticky;
  left: 0;
  background: #1a1d23;
  z-index: 20;
  box-shadow: 6px 0 8px -5px rgba(0, 0, 0, 0.3);
}

/* Ensure sticky header works with horizontal scroll */
.comparison-table th {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #1a1d23;
}

/* First header cell needs higher z-index when sticky */
.comparison-table th:first-child {
  z-index: 30;
  box-shadow: 
    6px 0 8px -5px rgba(0, 0, 0, 0.3),
    0 6px 8px -5px rgba(0, 0, 0, 0.3);
}

/* Enhanced scrollbar styling */
.comparison-table::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.comparison-table::-webkit-scrollbar-track {
  background: rgba(26, 29, 35, 0.3);
  border-radius: 4px;
}

.comparison-table::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #00bcd4, #4CAF50);
  border-radius: 4px;
  border: 2px solid rgba(26, 29, 35, 0.5);
}

/* Add scroll indicators */
.comparison-table::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30px;
  background: linear-gradient(90deg, transparent, rgba(26, 29, 35, 0.8));
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.comparison-table:not(:hover)::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

/* Show gradient when table is scrollable */
.comparison-table.is-scrollable::after {
  opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .comparison-table {
    margin: 0 -15px;
    padding: 0 15px;
  }
  
  .comparison-table table {
    min-width: 600px;
  }
}
  
/* Fix for sticky columns and scrolling */
.comparison-table {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding-bottom: 12px;
  background: #2a2d35; /* Match table background */
}

/* Ensure clean column edges when scrolling */
.comparison-table th,
.comparison-table td {
  position: relative;
  background: #2a2d35; /* Match table background */
}

/* Fix for sticky first column */
.comparison-table th:first-child,
.comparison-table td:first-child {
  position: sticky;
  left: 0;
  background: #1a1d23;
  z-index: 20;
  /* Improved shadow with no harsh edge */
  box-shadow: 6px 0 8px -5px rgba(0, 0, 0, 0.3);
  transform: translateZ(0); /* Force GPU acceleration */
}

/* Fix for header cells */
.comparison-table thead th {
  position: sticky;
  top: 0;
  background: #1a1d23 !important;
  z-index: 10;
}

/* Fix for the first header cell (corner) */
.comparison-table thead th:first-child {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 30; /* Higher z-index to stay on top */
  background: #1a1d23 !important;
  /* Improved shadow for corner cell */
  box-shadow: 
    6px 0 8px -5px rgba(0, 0, 0, 0.3),
    0 6px 8px -5px rgba(0, 0, 0, 0.3);
}

/* Ensure proper background for first column cells on hover */
.comparison-table tr:hover td:first-child {
  background: #1a1d23;
}

/* Fix table container */
.comparison-table {
  position: relative;
  overflow-x: auto;
  background: #2a2d35;
}

/* Ensure proper stacking context */
.comparison-table table {
  position: relative;
  z-index: 1;
}
  
/* Remove the problematic gradient overlay */
.comparison-table::after {
  display: none; /* Remove the gradient overlay completely */
}

/* Clean up table edges */
.comparison-table {
  overflow-x: auto;
  max-width: 100%;
  background: #2a2d35;
  padding: 0;  /* Remove padding that might cause edge issues */
  position: relative;
}

/* Ensure consistent column styling */
.comparison-table th,
.comparison-table td {
  background: #2a2d35;
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  min-width: 150px;
  box-sizing: border-box;
}

/* Clean up last column */
.comparison-table th:last-child,
.comparison-table td:last-child {
  border-right: none; /* Remove right border from last column */
}

/* Remove any scroll indicators or shadows */
.comparison-table.is-scrollable::after {
  display: none;
}
  