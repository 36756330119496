.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  padding: 20px;
}

.crypto-card {
  background: linear-gradient(145deg, #1a1d23, #2a2d35);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor: help;
}

.crypto-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  border-color: rgba(0, 188, 212, 0.3);
}

/* Glow effect on hover */
.crypto-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at center,
    rgba(0, 188, 212, 0.1) 0%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.crypto-card:hover::before {
  opacity: 1;
}

.crypto-card .title {
  color: #8a8f98;
  font-size: 0.9rem;
  margin-bottom: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  padding-bottom: 8px;
}

.crypto-card .title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background: linear-gradient(90deg, #00bcd4, #4CAF50);
  transition: width 0.3s ease;
  opacity: 1;
}

.crypto-card:hover .title::after {
  width: 100%;
}

.crypto-card.price .title::after {
  background: linear-gradient(90deg, #00bcd4, #00e5ff);
}

.crypto-card.volume .title::after {
  background: linear-gradient(90deg, #4CAF50, #8BC34A);
}

.crypto-card.market .title::after {
  background: linear-gradient(90deg, #2196F3, #03A9F4);
}

.crypto-card.transaction .title::after {
  background: linear-gradient(90deg, #9C27B0, #E91E63);
}

.crypto-card.ratio .title::after {
  background: linear-gradient(90deg, #FF9800, #FFC107);
}

.crypto-card .value {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
}

/* Tooltip styling */
.crypto-card {
  position: relative;
  cursor: help;
}

.crypto-card .tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.85rem;
  white-space: normal;
  width: 200px;
  text-align: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  pointer-events: none;
  margin-bottom: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.crypto-card:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Different accent colors for different card types */
.crypto-card.price { border-left: 3px solid #00bcd4; }
.crypto-card.volume { border-left: 3px solid #4CAF50; }
.crypto-card.market { border-left: 3px solid #2196F3; }
.crypto-card.transaction { border-left: 3px solid #9C27B0; }
.crypto-card.ratio { border-left: 3px solid #FF9800; }

/* Crypto header styling */
.crypto-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  padding: 20px;
  background: #2a2a2a;
  border-radius: 12px;
}

.crypto-header h2 {
  margin: 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
}

.crypto-header img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

/* Update the tooltip section */
.metric-header {
  position: relative;
  cursor: help;
  margin-bottom: 15px;
}

.metric-header .tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.85rem;
  white-space: normal;
  width: 200px;
  text-align: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  pointer-events: none;
  margin-bottom: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.metric-header:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Add a small arrow to the tooltip */
.metric-header .tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
}

/* Update the title and line gradient styles */
.metric-header .title {
  color: #8a8f98;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  padding-bottom: 8px;
}

/* Add the gradient line */
.metric-header .title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background: linear-gradient(90deg, #00bcd4, #4CAF50);
  transition: width 0.3s ease;
}

/* Extend line on hover */
.crypto-card:hover .metric-header .title::after {
  width: 100%;
}

/* Different gradient colors for different card types */
.crypto-card.price .metric-header .title::after {
  background: linear-gradient(90deg, #00bcd4, #00e5ff);
}

.crypto-card.volume .metric-header .title::after {
  background: linear-gradient(90deg, #4CAF50, #8BC34A);
}

.crypto-card.market .metric-header .title::after {
  background: linear-gradient(90deg, #2196F3, #03A9F4);
}

.crypto-card.transaction .metric-header .title::after {
  background: linear-gradient(90deg, #9C27B0, #E91E63);
}

.crypto-card.ratio .metric-header .title::after {
  background: linear-gradient(90deg, #FF9800, #FFC107);
}

/* Add these styles at the end of your file */

/* Chart container styling */
.chart-container {
  background: linear-gradient(145deg, #1a1d23, #2a2d35);
  border-radius: 12px;
  padding: 40px;
  margin: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 800px;
  display: flex;
  flex-direction: column;
}

.chart-container h3 {
  color: #8a8f98;
  font-size: 1.8rem;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

/* TradingView widget container styling */
.tradingview-widget-container {
  height: 650px;
  width: 100%;
  margin: 20px 0;
}

.tradingview-widget-container > div {
  height: 100% !important;
  width: 100% !important;
}

/* More specific selectors */
.container .chart-container {
  background: linear-gradient(145deg, #1a1d23, #2a2d35);
  border-radius: 18px;
  padding: 15px;
  margin: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 350px !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.container .chart-container h3 {
  color: #8a8f98;
  font-size: 1.2rem;
  margin-bottom: 2px;
  padding-bottom: 2px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  flex-shrink: 0;
  height: 20px;
}

.container .chart-container .tradingview-widget-container {
  flex: 1;
  width: 100%;
  min-height: 500px;
  margin: 0;
}

.container .chart-container .tradingview-widget-container > div {
  height: 100% !important;
  width: 100% !important;
}

/* Specific tooltip styling for CryptoStats page */
.container .card-grid .crypto-card {
  position: relative;
  overflow: visible;
}

.container .card-grid .crypto-card .tooltip {
  visibility: hidden;
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.85rem;
  white-space: normal;
  width: 200px;
  text-align: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  pointer-events: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

/* Position the tooltip on hover */
.container .card-grid .crypto-card:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Arrow styling for CryptoStats tooltips */
.container .card-grid .crypto-card .tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
}

/* Specific styles for CryptoStats page cards and tooltips */
.container .card-grid .crypto-card {
  position: relative;
  overflow: visible !important; /* Override any other overflow settings */
}

.container .card-grid .crypto-card .tooltip {
  visibility: hidden;
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.85rem;
  white-space: normal;
  width: 200px;
  text-align: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  pointer-events: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

/* Add this to specifically target home page cards */
.home-page .crypto-card {
  position: relative;
  overflow: hidden !important; /* Force overflow hidden for home page cards */
}

/* Position the tooltip on hover for stats page */
.container .card-grid .crypto-card:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Arrow styling for CryptoStats tooltips */
.container .card-grid .crypto-card .tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
}

/* Specific styles for home page cards */
.home-page .card-grid .crypto-card {
  position: relative;
  overflow: hidden !important; /* Force overflow hidden */
}

.home-page .card-grid .crypto-card > * {
  overflow: hidden !important; /* Force overflow hidden on all direct children */
}

.home-page .card-grid .crypto-card .inner-content {
  position: relative;
  width: 100%;
  overflow: hidden !important;
}

/* Keep the stats page styles */
.container .card-grid .crypto-card {
  position: relative;
  overflow: visible !important;
}



