/* src/components/SentimentAnalysis.css */

.sentiment-container {
    margin-top: 40px;
    padding: 30px;
    background-color: #2a2a2a;
    border-radius: 12px;
    color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sentiment-header {
    text-align: center;
    margin-bottom: 30px;
}

.sentiment-header h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #fff;
}

.sentiment-overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
    padding: 20px;
    background: #323232;
    border-radius: 8px;
}

.sentiment-stat {
    text-align: center;
    padding: 15px;
}

.sentiment-stat strong {
    display: block;
    color: #8a8f98;
    font-size: 0.9rem;
    margin-bottom: 8px;
}

.sentiment-value {
    font-size: 1.4rem;
    font-weight: 600;
}

.posts-section {
    background: #323232;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
}

.posts-section h4 {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: left;
}

.posts-list {
    list-style-type: none;
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
    scrollbar-width: thin;
    scrollbar-color: #4a4a4a #2a2a2a;
}

.posts-list::-webkit-scrollbar {
    width: 8px;
}

.posts-list::-webkit-scrollbar-track {
    background: #2a2a2a;
    border-radius: 4px;
}

.posts-list::-webkit-scrollbar-thumb {
    background: #4a4a4a;
    border-radius: 4px;
}

.post-item {
    margin-bottom: 15px;
    padding: 15px;
    background-color: #2a2a2a;
    border-radius: 8px;
    transition: transform 0.2s ease;
}

.post-item:hover {
    transform: translateX(5px);
    background-color: #383838;
}

.post-title {
    font-weight: 500;
    margin-bottom: 8px;
    color: #fff;
    font-size: 1rem;
}

.post-score {
    font-size: 0.9rem;
    color: #8a8f98;
    display: flex;
    align-items: center;
    gap: 8px;
}

.score-indicator {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
}

.score-positive {
    background-color: rgba(40, 167, 69, 0.2);
    color: #28a745;
}

.score-negative {
    background-color: rgba(220, 53, 69, 0.2);
    color: #dc3545;
}

.score-neutral {
    background-color: rgba(108, 117, 125, 0.2);
    color: #6c757d;
}

.wordcloud-section {
    background: #323232;
    border-radius: 8px;
    padding: 20px;
}

.wordcloud-section h4 {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: left;
}

.wordcloud-container {
    width: 100%;
    height: 400px;
    background: #2a2a2a;
    border-radius: 8px;
    padding: 20px;
}

.sentiment-container.error {
    background-color: rgba(220, 53, 69, 0.1);
    border: 1px solid rgba(220, 53, 69, 0.2);
    color: #dc3545;
    text-align: center;
    padding: 20px;
}
