/* frontend/src/styles.css */

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #121212;
    color: #ffffff;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .container {
    width: 90%;
    margin: auto;
    padding-top: 20px;
  }
  
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .card {
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
  }
  
  .card h3 {
    margin-bottom: 10px;
  }
  
  .navbar {
    background-color: #1e1e1e;
    padding: 10px 20px;
  }
  
  .navbar h1 {
    margin: 0;
  }
  
  .button {
    background-color: #6200ea;
    padding: 10px 20px;
    border-radius: 8px;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    border: none;
  }
  
  .button:hover {
    background-color: #3700b3;
  }
  /* Additional styles */

.card {
    /* Existing styles */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .navbar {
    /* Existing styles */
    position: sticky;
    top: 0;
    z-index: 100;
  }
  
  .button {
    /* Existing styles */
    transition: background-color 0.2s ease;
  }
  
  h2 {
    font-weight: 300;
  }
  
  h3 {
    font-weight: 400;
  }
  
  p {
    font-weight: 300;
    font-size: 1.1em;
  }
  .chart-container {
    margin-top: 40px;
  }
  
  .chart-container h3 {
    margin-bottom: 20px;
  }
  
  /* Adjust the TradingView widget container */
  .tradingview-widget-container {
    width: 100%;
    height: 500px;
  }
  
  .tradingview-widget-container > div {
    width: 100%;
    height: 100%;
  }
  .navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-links {
  display: flex;
  gap: 15px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}
.posts-list {
  list-style-type: none;
  padding: 0;
}

.posts-list li {
  margin-bottom: 15px;
  background-color: #2e2e2e;
  padding: 10px;
  border-radius: 5px;
}

.posts-list p {
  margin: 5px 0;
}
  