.portfolio-login {
  max-width: 340px;
  margin: 2rem auto;
  padding: 2rem;
  background: rgba(26, 29, 35, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.36);
  font-family: 'Poppins', sans-serif;
}

.portfolio-login h2 {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.portfolio-login form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.portfolio-login input {
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  padding: 0.8rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease;
  display: block;
}

.portfolio-login input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.15);
}

.portfolio-login input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Poppins', sans-serif;
}

.portfolio-login button[type="submit"] {
  width: 280px;
  padding: 0.8rem;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.portfolio-login button[type="submit"]:hover {
  background: #45a049;
  transform: translateY(-1px);
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.2);
}

.portfolio-login button[type="submit"]:disabled {
  background: #2c3038;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.toggle-auth {
  margin-top: 1rem;
  background: none;
  border: none;
  color: #4CAF50;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  transition: color 0.3s ease;
  padding: 0.5rem 1rem;
}

.toggle-auth:hover {
  color: #45a049;
  text-shadow: 0 0 8px rgba(76, 175, 80, 0.1);
}

.toggle-auth:disabled {
  color: #2c3038;
  cursor: not-allowed;
  text-shadow: none;
}

.error-message {
  background: rgba(220, 53, 69, 0.1);
  border: 1px solid rgba(220, 53, 69, 0.3);
  color: #dc3545;
  padding: 0.8rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
}

.portfolio-manager {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.create-portfolio-form {
  margin: 1rem 0;
  padding: 1rem;
  background-color: #1a1a1a;
  border-radius: 8px;
  border: 1px solid #333;
}

.create-portfolio-form form {
  display: flex;
  gap: 1rem;
}

.create-portfolio-form input {
  flex: 1;
  padding: 0.5rem;
  background-color: #2a2a2a;
  border: 1px solid #444;
  border-radius: 4px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

.create-portfolio-form input::placeholder {
  color: #888;
  font-family: 'Poppins', sans-serif;
}

.create-portfolio-form button {
  padding: 0.5rem 1rem;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-family: 'Poppins', sans-serif;
}

.create-portfolio-form button:hover {
  background-color: #34495e;
}

.create-portfolio-form button:active {
  background-color: #243342;
}

.portfolios-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}

.portfolio-card {
  background: #1a1d23;
  padding: 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.portfolio-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.portfolio-card.selected {
  border: 2px solid #007bff;
}

.portfolio-details {
  margin-top: 2rem;
  background: #1a1d23;
  padding: 1.5rem;
  border-radius: 8px;
}

.error-message {
  background: #dc3545;
  color: white;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.portfolio-list-section,
.portfolio-details-section {
  background: #1a1d23;
  border-radius: 8px;
  padding: 1.5rem;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.metric-card {
  background: #1a1d23;
  padding: 1.25rem;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s;
}

.metric-card:hover {
  transform: translateY(-2px);
}

.metric-card h4 {
  color: #8b8d93;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
}

.metric-card p {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.5rem 0;
  color: #fff;
}

.metric-card small {
  display: block;
  color: #6c757d;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.risk-level {
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  display: inline-block;
}

.risk-level.low {
  background: #28a745;
  color: white;
}

.risk-level.moderate {
  background: #ffc107;
  color: black;
}

.risk-level.high {
  background: #dc3545;
  color: white;
}

.add-holding-form {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #2c3038;
  border-radius: 8px;
}

.add-holding-form form {
  display: grid;
  gap: 1rem;
}

.add-holding-form input {
  padding: 0.8rem;
  border: 1px solid #404550;
  border-radius: 4px;
  background: #1a1d23;
  color: white;
}

.holdings-list {
  margin-top: 2rem;
}

.holding-card {
  background: #2c3038;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  align-items: center;
}

.holding-card .holding-info {
  display: grid;
  gap: 0.5rem;
}

.holding-card .holding-value {
  font-size: 1.2rem;
  font-weight: bold;
}

.holding-card .holding-change {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.holding-change.positive {
  color: #28a745;
}

.holding-change.negative {
  color: #dc3545;
}

.portfolio-summary {
  background: #2c3038;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.portfolio-summary h3 {
  margin-bottom: 1rem;
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}

.summary-item {
  text-align: center;
}

.summary-item .label {
  color: #8b8d93;
  margin-bottom: 0.5rem;
}

.summary-item .value {
  font-size: 1.2rem;
  font-weight: bold;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

@media (max-width: 1024px) {
  .portfolio-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .metrics-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .metrics-grid {
    grid-template-columns: 1fr;
  }
  
  .holding-card {
    grid-template-columns: 1fr;
  }
}

.form-group {
  margin-bottom: 1rem;
}

.form-group small {
  display: block;
  color: #6c757d;
  margin-top: 0.25rem;
}

.error-message {
  background-color: #dc3545;
  color: white;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

/* Update button styles */
.create-portfolio-form button,
.add-holding-form button {
  background: #2c3e50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-portfolio-form button:hover,
.add-holding-form button:hover {
  background: #34495e;
}

.create-portfolio-form button:active,
.add-holding-form button:active {
  background: #2c3e50;
}

/* Optional: Add a subtle glow effect on focus */
.create-portfolio-form button:focus,
.add-holding-form button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.3);
}

.portfolio-details-section {
  background: #1a1d23;
  padding: 2rem;
  border-radius: 8px;
}

.holding-card {
  background: #2c3038;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  display: grid;
  gap: 1rem;
}

.holding-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.holding-value {
  font-weight: bold;
  font-size: 1.1rem;
}

.holding-change {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-weight: bold;
}

.holding-change.positive {
  color: #28a745;
}

.holding-change.negative {
  color: #dc3545;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group input {
  width: 100%;
  padding: 0.8rem;
  background: #2c3038;
  border: 1px solid #404550;
  border-radius: 4px;
  color: white;
}

.form-group small {
  display: block;
  color: #6c757d;
  margin-top: 0.25rem;
}

.portfolio-metrics {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #2c3038;
  border-radius: 8px;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.metric-card {
  background: #1a1d23;
  padding: 1.25rem;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s;
}

.metric-card:hover {
  transform: translateY(-2px);
}

.metric-card h4 {
  color: #8b8d93;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600;
}

.metric-card p {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.5rem 0;
  color: #fff;
}

.metric-card small {
  display: block;
  color: #6c757d;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.risk-level {
  display: inline-block;
  padding: 0.25rem 1rem;
  border-radius: 4px;
  font-size: 1.2rem !important;
}

.risk-level.low {
  background: #28a745;
  color: white;
}

.risk-level.moderate {
  background: #ffc107;
  color: black;
}

.risk-level.high {
  background: #dc3545;
  color: white;
}

/* Add responsive adjustments */
@media (max-width: 1200px) {
  .metrics-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

@media (max-width: 768px) {
  .metrics-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .metrics-grid {
    grid-template-columns: 1fr;
  }
}

.form-group small {
  display: block;
  color: #6c757d;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  line-height: 1.4;
  word-wrap: break-word;
}

/* Add tooltip for supported cryptocurrencies */
.crypto-tooltip {
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
  cursor: help;
}

.crypto-tooltip:hover .tooltip-content {
  display: block;
}

.tooltip-content {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;
  background: #2c3038;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: max-content;
  max-width: 300px;
  z-index: 1000;
}

.portfolio-instructions {
  background: #2c3038;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  margin: 1rem 0 2rem 0;
  border-left: 4px solid #3498db;
}

.portfolio-instructions p {
  color: #8b8d93;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.portfolio-instructions ol {
  margin: 0.5rem 0 0 1.2rem;
  color: #a4a6aa;
  font-size: 0.85rem;
}

.portfolio-instructions li {
  margin-bottom: 0.25rem;
}

.portfolio-instructions li:last-child {
  margin-bottom: 0;
}

.portfolio-item {
  /* Existing styles */
  border: 2px solid transparent; /* Add this */
  transition: all 0.2s ease; /* Add this for smooth transition */
}

.portfolio-item.selected {
  border-color: #007bff; /* Blue outline for selected portfolio */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3); /* Optional: adds subtle shadow */
}

.portfolio-value-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portfolio-change {
  font-size: 0.9em;
  font-weight: 500;
}

.portfolio-change.positive {
  color: #28a745;
}

.portfolio-change.negative {
  color: #dc3545;
}

.yield-value {
  font-weight: 500;
}

.yield-value.positive {
  color: #28a745;
}

.yield-value.negative {
  color: #dc3545;
}

/* Mobile responsiveness for Portfolio page */
@media screen and (max-width: 768px) {
  .portfolio-grid {
    grid-template-columns: 1fr; /* Stack columns instead of side-by-side */
    padding: 10px;
  }

  .holding-card {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .metrics-grid {
    grid-template-columns: 1fr; /* Single column for metrics */
    gap: 10px;
  }

  .add-holding-form form {
    flex-direction: column;
  }

  .form-group {
    width: 100%;
  }

  .portfolio-value-info {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Glass morphism for cards */
.metric-card, .holding-card, .portfolio-item {
  background: rgba(26, 29, 35, 0.8);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.36);
}

/* Animated gradient borders */
@keyframes gradientBorder {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.metric-card {
  position: relative;
  padding: 1.25rem;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.metric-card::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 1px;
  background: linear-gradient(
    45deg,
    #4CAF50,
    #00bcd4,
    #4CAF50
  );
  background-size: 200% 200%;
  animation: gradientBorder 6s ease infinite;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.metric-card:hover::before {
  opacity: 1;
}

/* Enhanced portfolio items */
.portfolio-item {
  position: relative;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.portfolio-item.selected {
  background: rgba(76, 175, 80, 0.1);
  border: 1px solid rgba(76, 175, 80, 0.3);
}

.portfolio-item:hover {
  transform: translateY(-5px);
}

/* Animated value changes */
@keyframes valueChange {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.value-changed {
  animation: valueChange 0.3s ease;
}

/* Modern form styling */
.add-holding-form form {
  background: rgba(26, 29, 35, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
}

.form-group input {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px;
  color: white;
  transition: all 0.3s ease;
}

.form-group input:focus {
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

/* Holdings list improvements */
.holding-card {
  position: relative;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
}

.holding-card::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 1px;
  background: linear-gradient(
    45deg,
    var(--gradient-start, #4CAF50),
    var(--gradient-end, #00bcd4)
  );
  background-size: 200% 200%;
  animation: gradientBorder 6s ease infinite;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  opacity: 0.5;
}

/* Dynamic colors for positive/negative changes */
.holding-card[data-trend="positive"] {
  --gradient-start: #4CAF50;
  --gradient-end: #81c784;
}

.holding-card[data-trend="negative"] {
  --gradient-start: #f44336;
  --gradient-end: #ff7043;
}

/* Loading states */
@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

.loading-skeleton {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.05) 25%,
    rgba(255, 255, 255, 0.1) 37%,
    rgba(255, 255, 255, 0.05) 63%
  );
  background-size: 400% 100%;
  animation: shimmer 1.4s ease infinite;
  border-radius: 4px;
  height: 20px;
  margin: 10px 0;
}

/* Responsive improvements */
@media (max-width: 768px) {
  .portfolio-grid {
    grid-template-columns: 1fr;
  }
  
  .metric-card::before,
  .holding-card::before {
    animation: none;
  }
  
  .portfolio-metrics {
    overflow-x: auto;
    padding-bottom: 15px;
  }
}

/* Modern form styling improvements */
.add-holding-form {
  background: rgba(26, 29, 35, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 15px;
}

.form-group input {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px;
  color: white;
  transition: all 0.3s ease;
  font-size: 14px;
}

/* Contained glow effect */
.form-group input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.15);
}

/* Shorter placeholder text */
.form-group input::placeholder {
  opacity: 0.7;
}

/* Small helper text */
.form-group small {
  display: block;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}

/* Submit button styling */
.add-holding-form button[type="submit"] {
  width: 100%;
  padding: 12px;
  background: #4CAF50;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-holding-form button[type="submit"]:hover {
  background: #45a049;
  transform: translateY(-1px);
}

/* Update the portfolio grid layout for mobile */
@media screen and (max-width: 768px) {
  .portfolio-grid {
    grid-template-columns: 1fr; /* Single column on mobile */
    gap: 1rem;
    padding: 1rem;
  }

  /* Center all cards and sections */
  .portfolio-list-section,
  .portfolio-details-section {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }

  /* Adjust metrics grid for mobile */
  .metrics-grid {
    grid-template-columns: 1fr; /* Single column for metrics on mobile */
    gap: 0.8rem;
    padding: 0;
  }

  /* Center metric cards */
  .metric-card {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }

  /* Adjust holdings list */
  .holdings-list {
    width: 100%;
    padding: 0;
  }

  /* Center holding cards */
  .holding-card {
    width: 100%;
    margin: 0 auto 1rem auto;
    padding: 1rem;
  }

  /* Adjust forms for mobile */
  .create-portfolio-form,
  .add-holding-form {
    width: 100%;
    padding: 1rem;
    margin: 0 auto;
  }

  /* Center form inputs */
  .form-group input {
    width: 100%;
    margin: 0 auto;
  }

  /* Adjust portfolio items list */
  .portfolios-list {
    grid-template-columns: 1fr; /* Single column */
    gap: 0.8rem;
    padding: 0;
    width: 100%;
    margin: 1rem auto;
  }

  /* Center portfolio items */
  .portfolio-item {
    width: 100%;
    margin: 0 auto;
  }

  /* Adjust portfolio instructions */
  .portfolio-instructions {
    width: 100%;
    margin: 1rem auto;
    padding: 1rem;
  }

  /* Adjust headers */
  h2, h3 {
    text-align: center;
    margin-bottom: 1rem;
  }

  /* Adjust buttons */
  button {
    width: 100%;
    margin: 0 auto;
  }

  /* Fix any potential horizontal scroll */
  .portfolio-manager {
    max-width: 100vw;
    overflow-x: hidden;
  }
}

/* Additional optimization for very small screens */
@media screen and (max-width: 380px) {
  .portfolio-grid {
    padding: 0.5rem;
  }

  .metric-card,
  .holding-card,
  .portfolio-item {
    padding: 0.8rem;
  }

  .form-group input {
    padding: 0.8rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4 {
    font-size: 1rem;
  }
}

/* Base container styles */
.portfolio-grid {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

/* Mobile optimization */
@media screen and (max-width: 768px) {
  .portfolio-grid {
    width: 100%;
    padding: 10px;
    margin: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }

  .portfolio-manager {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  /* Reset right-side padding/margins */
  .portfolio-list-section,
  .portfolio-details-section {
    width: 100%;
    padding: 10px;
    margin: 0;
  }

  /* Fix metrics grid centering */
  .metrics-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .metric-card {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  /* Fix holdings list centering */
  .holdings-list {
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .holding-card {
    width: 100%;
    margin: 0 0 10px 0;
    box-sizing: border-box;
  }

  /* Fix portfolio items centering */
  .portfolios-list {
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .portfolio-item {
    width: 100%;
    margin: 0 0 10px 0;
    box-sizing: border-box;
  }

  /* Fix form centering */
  .add-holding-form {
    width: 100%;
    padding: 10px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .form-group {
    width: 100%;
    margin: 0 auto;
  }

  /* Ensure all elements use border-box */
  * {
    box-sizing: border-box;
  }
}
