/* src/components/Navbar.css */

.navbar {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #1e1e1e;
  overflow: visible;
}

.navbar h1 {
  margin: 0;
  color: #fff;
}

.navbar nav {
  margin-left: auto;
}

.navbar .nav-link {
  color: #fff;
  margin-left: 20px;
  text-decoration: none;
  font-size: 18px;
}

.navbar .nav-link:hover {
  color: #00bcd4;
  transition: color 0.3s ease;
}

.logout-btn {
  background: #dc3545;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease;
}

.logout-btn:hover {
  background: #c82333;
  transform: scale(1.05);
}

.login-btn {
  background: #007bff;
  color: white;
  padding: 12px 24px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease;
}

.login-btn:hover {
  background: #0056b3;
  text-decoration: none;
  transform: scale(1.05);
}

.nav-item {
  position: relative;
  display: inline-block;
}

.navbar-tooltip {
  visibility: hidden;
  width: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 8px 12px;
  border-radius: 4px;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
  font-size: 14px;
}

.navbar-tooltip::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

.nav-item:last-child .navbar-tooltip {
  right: 0;
  left: auto;
  transform: none;
  text-align: right;
}

.nav-item:last-child .navbar-tooltip::before {
  left: auto;
  right: 20px;
  transform: none;
}

.nav-item:hover .navbar-tooltip {
  visibility: visible;
  opacity: 1;
}

.nav-item a, 
.nav-item button {
  font-size: 16px;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  border-radius: 4px;
  transition: all 0.2s ease;
  font-family: 'Poppins', sans-serif;
}

.nav-item a:hover {
  color: #00bcd4;
  transform: scale(1.05);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Add these new mobile-specific styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  margin-left: auto;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 2px 0;
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: #1e1e1e;
    padding: 20px;
    gap: 10px;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-item {
    width: 100%;
  }

  .nav-item a, 
  .nav-item button {
    width: 100%;
    display: block;
    text-align: left;
  }

  .navbar-tooltip {
    display: none;
  }
}
