.chatbot-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chatbot-toggle {
  background: rgba(40, 44, 52, 0.9);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.chatbot-toggle:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
  background: rgba(40, 44, 52, 0.95);
}

.chatbot-container {
  width: 300px;
  height: 500px;
  background: rgba(26, 29, 35, 0.95);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
}

.chatbot-header {
  background: linear-gradient(90deg, #282c34, #363a43);
  color: white;
  padding: 15px 20px;
  border-radius: 16px 16px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.chatbot-header h3 {
  margin: 0;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 0 5px;
}

.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #1a1d23;
  color: #ffffff;
}

.message {
  max-width: 85%;
  padding: 12px 16px;
  border-radius: 15px;
  margin: 8px 0;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.message.user {
  background: linear-gradient(135deg, #007aff, #1a8cff);
  color: white;
  align-self: flex-end;
  border-bottom-right-radius: 5px;
}

.message.assistant {
  background: rgba(44, 49, 60, 0.95);
  color: #e1e1e1;
  align-self: flex-start;
  border-bottom-left-radius: 5px;
}

.input-form {
  padding: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  gap: 8px;
  background: rgba(26, 29, 35, 0.95);
  border-radius: 0 0 16px 16px;
}

.input-form input {
  flex-grow: 1;
  padding: 12px 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  outline: none;
  background: rgba(40, 44, 52, 0.6);
  color: #ffffff;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease;
}

.input-form input:focus {
  border-color: #007aff;
  box-shadow: 0 0 0 2px rgba(0, 122, 255, 0.1);
  background: rgba(40, 44, 52, 0.8);
}

.input-form input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Poppins', sans-serif;
}

.input-form button {
  background: linear-gradient(135deg, #007aff, #1a8cff);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.input-form button:hover:not(:disabled) {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.input-form button:disabled {
  background: #3a3f4b;
  cursor: not-allowed;
  box-shadow: none;
}

.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 4px 8px;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: typing 1s infinite ease-in-out;
}

.typing-indicator span:nth-child(2) { animation-delay: 0.2s; }
.typing-indicator span:nth-child(3) { animation-delay: 0.4s; }

@keyframes typing {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-4px); }
} 