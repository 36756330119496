.blockchain-monitor {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.monitor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.chain-selector {
  padding: 10px;
  border-radius: 8px;
  background: #2a2a2a;
  color: white;
  border: 1px solid #3a3a3a;
}

.monitor-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  grid-auto-rows: minmax(min-content, max-content);
}

.monitor-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card {
  background: #2a2a2a;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.network-trends {
  height: 400px;
  margin-top: 20px;
  padding: 15px;
  background: #323232;
  border-radius: 8px;
}

.network-trends h3 {
  margin-bottom: 15px;
  color: #fff;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: #323232;
  border-radius: 8px;
  position: relative;
  cursor: help;
}

.stat-label {
  color: #8a8f98;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
}

.transaction-list {
  max-height: 600px;
  overflow-y: auto;
}

.transaction-item {
  padding: 15px;
  background: #323232;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.transaction-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tx-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
}

.tx-type {
  color: #00bcd4;
  font-weight: 500;
}

.tx-time {
  color: #8a8f98;
  font-size: 0.9rem;
  white-space: nowrap;
}

.tx-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tx-amount {
  color: #4caf50;
  font-weight: 600;
}

.tx-count {
  color: #8a8f98;
  font-size: 0.9rem;
}

/* Scrollbar styling */
.transaction-list::-webkit-scrollbar {
  width: 8px;
}

.transaction-list::-webkit-scrollbar-track {
  background: #2a2a2a;
}

.transaction-list::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 4px;
}

.detailed-stats,
.velocity-metrics {
  margin-top: 30px;
  padding: 20px;
  background: #323232;
  border-radius: 8px;
}

.detailed-stats h3,
.velocity-metrics h3 {
  margin-bottom: 15px;
  color: #fff;
  font-size: 1.1rem;
}

.stats-grid.detailed,
.stats-grid.velocity {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.velocity-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.velocity-unit {
  font-size: 0.8rem;
  color: #8a8f98;
  margin-top: 2px;
}

.stat-item {
  background: #2a2a2a;
  padding: 15px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.stat-label {
  display: block;
  color: #8a8f98;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
}

/* Add gradients to different stat types */
.detailed .stat-item {
  border-left: 3px solid #4CAF50;
}

.velocity .stat-item {
  border-left: 3px solid #2196F3;
}

.live-transactions {
  margin-top: 20px;
  padding: 20px;
  background: #323232;
  border-radius: 8px;
}

.live-transactions h3 {
  margin-bottom: 15px;
  color: #fff;
}

.transaction-feed {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
  max-height: none;
}

.live-tx-item {
  background: #2a2a2a;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  animation: fadeIn 0.5s ease-in-out;
}

.live-tx-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tx-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
}

.tx-hash {
  color: #00bcd4;
  font-family: monospace;
  font-size: 0.9rem;
  word-break: break-all;
  flex: 1;
}

.tx-time {
  color: #8a8f98;
  font-size: 0.9rem;
  white-space: nowrap;
}

.tx-addresses {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.tx-from, .tx-to {
  color: #4caf50;
  font-family: monospace;
  font-size: 0.9rem;
}

.tx-arrow {
  color: #8a8f98;
}

.tx-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tx-value {
  color: #4caf50;
  font-weight: 600;
}

.tx-size {
  color: #8a8f98;
  font-size: 0.9rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fee-overview,
.mempool-status {
  margin-top: 20px;
  padding: 20px;
  background: #2a2a2a;
  border-radius: 8px;
}

.fee-overview h3,
.mempool-status h3 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 1.1rem;
}

.fee-grid,
.mempool-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.mempool-grid {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.fee-item,
.mempool-item {
  background: #323232;
  padding: 15px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.fee-item:hover,
.mempool-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.fee-label,
.mempool-label {
  display: block;
  color: #8a8f98;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.fee-value,
.mempool-value {
  display: block;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
}

.congestion-indicator {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 12px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  min-width: 80px;
}

/* Add gradients to different sections */
.fee-item {
  border-left: 3px solid #2196F3;
}

.mempool-item {
  border-left: 3px solid #4CAF50;
}

.transactions.card {
  display: flex;
  flex-direction: column;
  height: calc(12 * 100px); /* Approximate height for 12 transactions */
}

.transactions.card h3 {
  margin-bottom: 15px;
}

.transaction-feed {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.live-tx-item {
  height: 85px; /* Fixed height for each transaction */
  margin-bottom: 15px;
  background: #2a2a2a;
  border-radius: 8px;
  padding: 15px;
  transition: all 0.3s ease;
}

/* Scrollbar styling */
.transaction-feed::-webkit-scrollbar {
  width: 8px;
}

.transaction-feed::-webkit-scrollbar-track {
  background: #2a2a2a;
  border-radius: 4px;
}

.transaction-feed::-webkit-scrollbar-thumb {
  background: #454545;
  border-radius: 4px;
}

.transaction-feed::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Ensure consistent spacing */
.tx-header, .tx-body {
  margin-bottom: 8px;
}

.tx-addresses {
  margin-bottom: 8px;
}

.transaction-stats {
  margin-top: 20px;
  padding: 20px;
  background: #323232;
  border-radius: 8px;
}

.transaction-stats h3 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 1.1rem;
}

.transaction-stats .stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.transaction-stats .stat-item {
  background: #2a2a2a;
  padding: 15px;
  border-radius: 8px;
  border-left: 3px solid #4CAF50;
}

.transaction-stats .stat-count {
  display: block;
  color: #8a8f98;
  font-size: 0.9rem;
  margin-top: 5px;
}

/* Optional: Custom tooltip styling */
.stat-item:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  border-radius: 6px;
  font-size: 0.85rem;
  white-space: normal;
  width: 400px; /* Increased width */
  max-width: 90vw; /* Prevent overflow on small screens */
  z-index: 1000;
  pointer-events: none;
  transition: opacity 0.2s;
  line-height: 1.4; /* Tighter line height */
  margin-bottom: 8px; /* Space between tooltip and stat */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Ensure tooltip stays visible when near screen edges */
.stat-item:hover::after {
  left: 50%;
  transform: translateX(-50%);
}

/* Adjust position for stats near the right edge */
.stat-item:last-child:hover::after {
  left: auto;
  right: 0;
  transform: none;
}

/* Adjust position for stats near the left edge */
.stat-item:first-child:hover::after {
  left: 0;
  transform: none;
}

.blockchain-monitor select {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #454545;
  background-color: #2a2a2a;
  color: #fff;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease;
}

.blockchain-monitor select:hover {
  background-color: #323232;
  border-color: #666;
}

.blockchain-monitor select:focus {
  border-color: #00bcd4;
  box-shadow: 0 0 0 2px rgba(0, 188, 212, 0.2);
}

.blockchain-monitor select option {
  background-color: #2a2a2a;
  color: #fff;
  padding: 8px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/* Add specific positioning for the Network Load tooltip */
.velocity-metrics .stat-item:last-child:hover::after {
  left: auto;
  right: -100px; /* Adjust this value to shift the tooltip more to the right */
  transform: none;
}

/* Add specific positioning for both Network Load and Mempool Size tooltips */
.velocity-metrics .stat-item:last-child:hover::after,
.transaction-stats .stat-item:nth-child(3):hover::after {
  left: auto;
  right: -100px;
  transform: none;
}

/* Enhanced section title styling */
.network-stats h2,
.transactions h3,
.velocity-metrics h3,
.network-trends h3,
.transaction-stats h3 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #454545;
  display: flex;
  align-items: center;
  position: relative;
}

/* Add accent line under each title */
.network-stats h2::after,
.transactions h3::after,
.velocity-metrics h3::after,
.network-trends h3::after,
.transaction-stats h3::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 60px;
  height: 2px;
  background: #00bcd4;
  transition: width 0.3s ease;
}

/* Hover effect on titles */
.network-stats h2:hover::after,
.transactions h3:hover::after,
.velocity-metrics h3:hover::after,
.network-trends h3:hover::after,
.transaction-stats h3:hover::after {
  width: 100px;
}

/* Specific color accents for different sections */
.network-stats h2::after { background: #00bcd4; }
.transactions h3::after { background: #4CAF50; }
.velocity-metrics h3::after { background: #2196F3; }
.network-trends h3::after { background: #9C27B0; }
.transaction-stats h3::after { background: #FF9800; }

/* Add subtle text shadow for depth */
.network-stats h2,
.transactions h3,
.velocity-metrics h3,
.network-trends h3,
.transaction-stats h3 {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

/* Enhanced metric cards styling */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 25px;
}

.stat-item {
  background: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
  transition: all 0.3s ease;
  border-left: 3px solid transparent;
  position: relative;
  cursor: help;
}

/* Specific styling for basic stats (blocks, difficulty, hashrate) */
.network-stats .stats-grid:first-of-type .stat-item:nth-child(1) {
  border-left-color: #00bcd4;
  background: linear-gradient(145deg, #2a2a2a, #323232);
}

.network-stats .stats-grid:first-of-type .stat-item:nth-child(2) {
  border-left-color: #4CAF50;
  background: linear-gradient(145deg, #2a2a2a, #323232);
}

.network-stats .stats-grid:first-of-type .stat-item:nth-child(3) {
  border-left-color: #2196F3;
  background: linear-gradient(145deg, #2a2a2a, #323232);
}

/* Common stat item styling */
.stat-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.stat-label {
  display: block;
  color: #8a8f98;
  font-size: 0.9rem;
  margin-bottom: 8px;
  font-weight: 500;
}

.stat-value {
  display: block;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 4px;
}

.stat-count {
  display: block;
  color: #8a8f98;
  font-size: 0.85rem;
}

/* Velocity metrics specific styling */
.velocity-metrics .stat-item {
  border-left-color: #9C27B0;
}

/* Transaction stats specific styling */
.transaction-stats .stat-item {
  border-left-color: #FF9800;
}

/* Keep tooltip functionality */
.stat-item:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  border-radius: 6px;
  font-size: 0.85rem;
  white-space: normal;
  width: 400px;
  max-width: 90vw;
  z-index: 1000;
  pointer-events: none;
  transition: opacity 0.2s;
  line-height: 1.4;
  margin-bottom: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Add this specific adjustment for the hashrate tooltip */
.network-stats .stats-grid:first-of-type .stat-item:nth-child(3):hover::after {
  left: 5%;  /* Adjust this value to move the hashrate tooltip slightly to the right */
}

.monitor-header h1 {
  position: relative;
  padding-bottom: 10px;
}

.monitor-header h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 2px;
  background: linear-gradient(90deg, #00bcd4, #4CAF50);
  transition: width 0.3s ease;
}

.monitor-header h1:hover::after {
  width: 100%;
}

/* Add gradient animation keyframes */
@keyframes gradientMove {
  0% { background-position: 100% 0; }
  100% { background-position: -100% 0; }
}

/* Add gradient to Network Health title */
.network-stats h2::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 60px;
  height: 2px;
  background: linear-gradient(90deg, 
    #4CAF50, 
    #66bb6a,
    #81c784, 
    transparent
  );
  background-size: 200% 100%;
  animation: gradientMove 8s ease infinite;
}

/* Add gradient to section titles */
.velocity-metrics h3::after,
.network-trends h3::after,
.transaction-stats h3::after,
.transactions h3::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 60px;
  height: 2px;
  background: linear-gradient(90deg, 
    #4CAF50, 
    #66bb6a,
    #81c784, 
    transparent
  );
  background-size: 200% 100%;
  animation: gradientMove 8s ease infinite;
}

/* Add gradient border to stat items */
.stat-item {
  position: relative;
  border: none;
}

.stat-item::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: linear-gradient(90deg, 
    #4CAF50, 
    #66bb6a,
    #81c784, 
    transparent
  );
  background-size: 200% 100%;
  animation: gradientMove 8s ease infinite;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

/* Add gradient to live transaction items */
.live-tx-item {
  position: relative;
  border: none;
}

.live-tx-item::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 1px;
  background: linear-gradient(90deg, 
    #4CAF50, 
    #66bb6a,
    #81c784, 
    transparent
  );
  background-size: 200% 100%;
  animation: gradientMove 8s ease infinite;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

/* Vary animation speeds for different elements */
.stat-item:nth-child(2n)::before {
  animation-duration: 10s;
}

.stat-item:nth-child(3n)::before {
  animation-duration: 12s;
}

.live-tx-item:nth-child(2n)::before {
  animation-duration: 9s;
}

.live-tx-item:nth-child(3n)::before {
  animation-duration: 11s;
}

/* Add media query for mobile adjustments */
@media screen and (max-width: 768px) {
  .tx-details {
    margin-top: -8px; /* Pull up the details section */
    margin-bottom: 8px; /* Add space before the gradient border */
  }

  .live-tx-item {
    padding: 12px; /* Slightly reduce padding */
    height: auto; /* Allow height to adjust based on content */
    min-height: 85px; /* Maintain minimum height */
  }

  .tx-hash, .tx-size {
    font-size: 0.8rem; /* Slightly reduce font size */
  }

  .tx-from, .tx-to {
    font-size: 0.85rem; /* Slightly reduce font size */
  }

  .tx-value {
    font-size: 0.9rem; /* Slightly reduce font size */
  }

  /* Ensure gradient border doesn't overlap with content */
  .live-tx-item::before {
    bottom: 2px; /* Pull up the gradient border slightly */
  }
}

.tx-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tx-value {
  color: #4CAF50;
  font-weight: 600;
  font-size: 0.9rem;
}

/* Update mobile styles */
@media screen and (max-width: 768px) {
  .tx-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .tx-value {
    margin-bottom: 2px;
  }
}

/* Add/update media queries for mobile optimization */
@media screen and (max-width: 768px) {
  .blockchain-monitor {
    padding: 10px;
  }

  .monitor-header {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .monitor-header h1 {
    font-size: 1.5rem;
    margin: 0;
  }

  .chain-selector {
    width: 100%;
    padding: 12px;
    font-size: 16px; /* Larger touch target */
  }

  /* Adjust grid layout for mobile */
  .monitor-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  /* Stats grid adjustments */
  .stats-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
    gap: 10px;
  }

  .stat-item {
    padding: 12px;
    min-height: auto;
  }

  .stat-label {
    font-size: 0.8rem;
  }

  .stat-value {
    font-size: 1rem;
  }

  /* Network trends chart */
  .network-trends {
    height: 300px; /* Shorter on mobile */
    padding: 10px;
    margin-top: 15px;
  }

  .network-trends h3 {
    font-size: 1rem;
  }

  /* Transaction feed adjustments */
  .transaction-feed {
    max-height: 400px;
  }

  .live-tx-item {
    padding: 10px;
  }

  .tx-header {
    flex-direction: column;
    gap: 5px;
  }

  .tx-hash {
    font-size: 0.75rem;
    word-break: break-all;
  }

  .tx-time {
    font-size: 0.75rem;
  }

  .tx-addresses {
    flex-direction: column;
    gap: 5px;
  }

  .tx-from, .tx-to {
    font-size: 0.75rem;
    word-break: break-all;
  }

  .tx-arrow {
    display: none; /* Hide arrow on mobile */
  }

  .tx-details {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    margin-top: 8px;
  }

  .tx-value, .tx-size {
    font-size: 0.75rem;
  }

  /* Velocity metrics adjustments */
  .velocity-metrics {
    margin-top: 20px;
  }

  .velocity-metrics .stats-grid {
    grid-template-columns: 1fr; /* Stack velocity stats on mobile */
  }

  .velocity-unit {
    font-size: 0.7rem;
  }

  /* Transaction statistics adjustments */
  .transaction-stats {
    margin-top: 20px;
  }

  .transaction-stats .stats-grid {
    grid-template-columns: 1fr; /* Stack transaction stats on mobile */
  }

  /* Card adjustments */
  .card {
    padding: 15px;
  }

  /* Improve scrollbar for touch devices */
  .transaction-feed::-webkit-scrollbar {
    width: 6px;
  }

  .transaction-feed::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
  }

  /* Adjust headings */
  h2, h3 {
    font-size: 1.2rem;
    margin-bottom: 12px;
  }

  /* Loading state */
  .loading {
    font-size: 1rem;
    padding: 10px;
  }
}

/* Additional optimization for very small screens */
@media screen and (max-width: 380px) {
  .stats-grid {
    grid-template-columns: 1fr; /* Single column for very small screens */
  }

  .monitor-header h1 {
    font-size: 1.3rem;
  }

  .stat-item {
    padding: 10px;
  }
}