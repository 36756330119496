.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  padding: 20px;
}

.crypto-card {
  background-color: #1a1d23;
  border-radius: 12px;
  padding: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid #3a3f4b;
  color: white;
  text-decoration: none;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.crypto-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.crypto-card:hover::after {
  animation: gradientMove 2s ease infinite;
}

.crypto-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
  width: 100%;
  overflow: hidden;
  max-width: calc(100% - 25px);
}

.crypto-header img {
  width: 45px;
  height: 45px;
  object-fit: contain;
}

.crypto-header h3 {
  margin: 0;
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 95px);
}

.crypto-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.price-container,
.transactions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #3a3f4b;
}

.label {
  color: #6c757d;
  font-size: 0.9rem;
}

.value {
  font-weight: 600;
  color: #ffffff;
}

.loading-spinner {
  text-align: center;
  color: #6c757d;
  padding: 10px;
}

a {
  text-decoration: none;
  color: inherit;
}

.name-price-change {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-grow: 1;
  width: calc(100% - 45px);
  overflow: hidden;
  position: relative;
}

.price-change {
  font-size: 0.8rem;
  font-weight: 500;
  padding: 2px 3px;
  border-radius: 3px;
  width: 50px;
  text-align: right;
  position: absolute;
  right: 0;
  background: #1a1d23;
}

.price-change.positive {
  color: #00ff88;
}

.price-change.negative {
  color: #ff4d4d;
}

.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  background: #2a2a2a;
  border-radius: 12px;
}

.title-section h2 {
  margin: 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
}

.title-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #00bcd4, #4CAF50);
  transition: width 0.3s ease;
}

.title-section h2:hover::after {
  width: 100px;
}

.aggregate-stats {
  display: flex;
  gap: 30px;
}

.stat-item {
  position: relative;
}

.stat-label {
  color: #8a8f98;
  font-size: 0.9rem;
}

.stat-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
}

.stat-value.positive {
  color: #28a745;
}

.stat-value.negative {
  color: #dc3545;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .home-header {
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }

  .aggregate-stats {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .card-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    padding: 10px;
  }

  .crypto-card {
    padding: 15px;
  }

  .crypto-header h3 {
    font-size: 0.9rem;
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    line-height: 1.2;
    max-width: calc(100% - 55px);
  }

  .name-price-change {
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }

  .price-change {
    position: static;
    width: auto;
    font-size: 0.75rem;
    padding: 1px 4px;
    margin-left: 0;
  }
}

/* More specific selectors to disable tooltips on home page */
.container .home-header .stat-item .tooltip,
.container .home-header .stat-item::before,
.container .home-header .stat-item::after,
.container .home-header .tooltip,
.container .home-header [title]::before,
.container .home-header [title]::after {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  pointer-events: none !important;
}

/* Remove any hover effects related to tooltips */
.container .home-header .stat-item:hover .tooltip,
.container .home-header:hover .tooltip {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

/* Add the gradient animation keyframes if not already present */
@keyframes gradientMove {
  0% { background-position: 100% 0; }
  100% { background-position: -100% 0; }
}

/* Add the animated gradient border effect */
.crypto-card::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 1px;
  background: linear-gradient(90deg, 
    #4CAF50, 
    #66bb6a,
    #81c784, 
    transparent
  );
  background-size: 200% 100%;
  animation: gradientMove 3s ease infinite;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  pointer-events: none;
}

/* Update hover effect to work with the gradient */
.crypto-card:hover::after {
  animation: gradientMove 2s ease infinite;
}

/* Add multiple gradient animations with different speeds */
@keyframes gradientMove1 {
  0% { background-position: 100% 0; }
  100% { background-position: -100% 0; }
}

@keyframes gradientMove2 {
  0% { background-position: -100% 0; }
  100% { background-position: 100% 0; }
}

@keyframes gradientMove3 {
  0% { background-position: 50% 0; }
  100% { background-position: -150% 0; }
}

/* Define color gradients for positive and negative changes */
.crypto-card[data-trend="positive"]::after {
  background: linear-gradient(90deg, 
    #4CAF50, 
    #66bb6a,
    #81c784, 
    transparent
  );
}

.crypto-card[data-trend="negative"]::after {
  background: linear-gradient(90deg, 
    #ff4d4d, 
    #ff6666,
    #ff8080, 
    transparent
  );
}

/* Update the crypto card styles with conditional colors */
.crypto-card:nth-child(3n+1)::after {
  background-size: 200% 100%;
  animation: gradientMove1 5s ease-in-out infinite;
}

.crypto-card:nth-child(3n+2)::after {
  background-size: 200% 100%;
  animation: gradientMove2 7s ease-in-out infinite;
}

.crypto-card:nth-child(3n+3)::after {
  background-size: 200% 100%;
  animation: gradientMove3 6s ease-in-out infinite;
}

/* Update hover effects with faster animations */
.crypto-card:nth-child(3n+1):hover::after {
  animation: gradientMove1 3s ease-in-out infinite;
}

.crypto-card:nth-child(3n+2):hover::after {
  animation: gradientMove2 4s ease-in-out infinite;
}

.crypto-card:nth-child(3n+3):hover::after {
  animation: gradientMove3 3.5s ease-in-out infinite;
}